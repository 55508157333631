import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/Home.vue";
const Checklists = () => import("../components/Checklists.vue");
const PdfGeneration = () => import("../components/PdfGeneration.vue");
const PrivacyPolicy = () => import("../components/PrivacyPolicy.vue");
const AnnouncementList = () => import("../components/AnnouncementList.vue");
const Templates = () => import("../components/admin/Templates.vue");
const Organisations = () => import("../components/admin/Organisations.vue");
const Vessels = () => import("../components/admin/Vessels.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/checklists",
    name: "Checklists",
    component: Checklists,
  },
  {
    path: "/templates",
    name: "Templates",
    component: Templates,
  },
  {
    path: "/pdf-generation",
    name: "PdfGeneration",
    component: PdfGeneration,
  },
  {
    path: "/announcement-list",
    name: "AnnouncementList",
    component: AnnouncementList,
  },
  {
    path: "/organisations",
    name: "Organisations",
    component: Organisations,
  },
  {
    path: "/vessels",
    name: "Vessels",
    component: Vessels,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
