import ModuleBase from "../common/ModuleBase";

export default ModuleBase({
  state: {
    announcement_list_selected_collection: null,
    announcement_list_selected_collection_ids: [],
  },

  getters: {
    announcement_list_selected_collection_ids({
      announcement_list_selected_collection_ids,
    }) {
      return announcement_list_selected_collection_ids;
    },
  },
  mutations: {
    clearLocalData(state) {
      state.announcement_list_selected_collection = null;
    },
  },
  actions: {},
});
