import _ from "lodash";
import * as _debug from "debug";
import ApiClient from "@/lib/ApiClient";

import ModuleBase from "../common/ModuleBase";
import { objectDiff, sureClone } from "@/lib/DataUtils";
import Vue from "vue";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:ChecklistRowsStore`);

const DEFAULT_EDITOR = {
  id: null,
  text: null,
  information: null,
  checklistId: null,
  imageFileName: null,
};

export default ModuleBase({
  state: {
    checklistRows: {},
    editor: sureClone(DEFAULT_EDITOR),
  },

  getters: {
    checklistRows({ checklistRows }) {
      let values = _.values(checklistRows);
      return values;
    },
    rowsByChecklist:
      ({ checklistRows }) =>
      (id) => {
        var rows = _.values(checklistRows).filter((c) => c.checkListId === id);
        var rowsInOrder = _.orderBy(rows, ["sequenceNumber"], ["asc"]);
        return rowsInOrder;
      },
    checklistRowById:
      ({ checklistRows }) =>
      (id) => {
        var x = _.values(checklistRows).find((c) => c.id === id);
        return x;
      },
    editorChanges(state) {
      const id = _.get(state, "editor.id");
      if (!id) {
        return null;
      }
      const original = _.get(state.checklistRows, id);
      if (!original) {
        return null;
      }
      return objectDiff(original, state.editor);
    },
    editorHasChanges(state, getters) {
      let diff = getters.editorChanges;
      return diff && !_.isEmpty(diff);
    },
  },

  mutations: {
    newChecklistRowToEditor(state) {
      state.editor = sureClone(DEFAULT_EDITOR);
    },
    addChecklistRow(state, checklistRow) {
      Vue.set(state.checklistRows, checklistRow.id, checklistRow);
    },
    deleteChecklistRow(state, rowId) {
      Vue.delete(state.checklistRows, rowId);
    },
    clearChecklistRows(state) {
      state.checklistRows = [];
    },
  },
  actions: {
    async load({ commit }) {
      debug("load");
      let checklistRows = await ApiClient.get(`/checklistRows`);
      debug("load", checklistRows);
      commit("set", ["checklistRows", _.keyBy(checklistRows, "id")]);
    },
    async loadChecklistRowToEditor({ state, commit }, checklistRowId) {
      debug("loadChecklistRowToEditor", checklistRowId);
      const checklistRow = _.get(state.checklistRows, checklistRowId);
      debug("loadChecklistRowToEditor", checklistRow);
      if (!checklistRow) {
        throw new Error("ChecklistRow not found");
      }
      commit("set", ["editor", sureClone(checklistRow)]);
    },
    async saveChecklistRowInEditor({ state, getters, commit }) {
      let checklistRowId = state.editor.id;
      if (!getters.editorHasChanges) {
        debug("Ei tallennettavia muutoksia");
        return;
      }
      let modifiedChecklistRow = sureClone(state.editor);
      debug("saveChecklistRowInEditor", checklistRowId, modifiedChecklistRow);
      let result = await ApiClient.put(
        `/checklistRows/${checklistRowId}`,
        modifiedChecklistRow,
      );
      debug("saveChecklistRowInEditor, result", result);
      if (result) {
        commit("addChecklistRow", result);
      }
    },
  },
});
