import Vue from "vue";
import Vuetify, {
  VTextField,
  VTextarea,
  VMenu,
  VInput,
  VFileInput,
  VSelect,
  VLabel,
  VMessages,
  VCounter,
  VChip,
  VSimpleCheckbox,
  VCheckbox,
  VTreeview,
} from "vuetify/lib";
import fi from "vuetify/lib/locale/fi";

Vue.use(Vuetify, {
  components: {
    VTextField,
    VTextarea,
    VMenu,
    VInput,
    VFileInput,
    VSelect,
    VLabel,
    VMessages,
    VCounter,
    VChip,
    VSimpleCheckbox,
    VCheckbox,
    VTreeview,
  },
});

export default new Vuetify({
  lang: {
    locales: { fi },
    current: "fi",
  },
  theme: {
    themes: {
      light: {
        primary: "#122d43",
        secondary: "#ecf4f7",
        accent: "#c3343e",
        error: "#c3343e",
      },
    },
  },
});
