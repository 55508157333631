import _ from "lodash";
import * as _debug from "debug";
import ApiClient from "@/lib/ApiClient";

import ModuleBase from "../common/ModuleBase";
import Vue from "vue";

import { findFirst } from "obj-traverse/lib/obj-traverse";
import { sureClone } from "../../lib/DataUtils";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:CatalogStore`);

const DEFAULT_EDITOR = {
  id: null,
  parentId: null,
  templateItemId: null,
};

const DEFAULT_COLLECTION_EDITOR = {
  name: null,
  description: null,
  organisationId: null,
};

const DEFAULT_CATEGORY_EDITOR = {
  name: null,
  information: null,
  printToPdfDefault: true,
};

const DEFAULT_CHECKLIST_EDITOR = {
  name: null,
  isCrossCheckList: false,
  isInformationOnly: false,
  printToPdfDefault: true,
};

const DEFAULT_CHECKLISTROW_EDITOR = {
  text: null,
  information: null,
  imageFileName: null,
};

export default ModuleBase({
  state: {
    catalog: {},
    pdfDownloadUrl: null,
    editor: sureClone(DEFAULT_EDITOR),
  },

  getters: {
    catalog({ catalog }) {
      let values = _.values(catalog);
      return values;
    },
    catalogItemById:
      ({ catalog }) =>
      (catalogItemId) => {
        let values = _.values(catalog);
        var first;
        for (let v of values) {
          first = findFirst(v, "children", { id: catalogItemId });
          if (first) {
            break;
          }
        }
        return first;
      },
    catalogItemByTargetId:
      ({ catalog }) =>
      (targetId) => {
        let values = _.values(catalog);
        var first;
        for (let v of values) {
          if (v.targetId === targetId) {
            first = v;
            break;
          }
          first = findFirst(v, "children", { targetId: targetId });
          if (first) {
            break;
          }
        }
        return first;
      },
    pdfDownloadUrl({ pdfDownloadUrl }) {
      return pdfDownloadUrl;
    },
  },

  mutations: {
    addCatalogItem(state, catalogItem) {
      Vue.set(state.catalog, catalogItem.id, catalogItem);
    },
    clearCatalog(state) {
      state.catalog = [];
    },
    deleteCatalogItem(state, catalogItemId) {
      Vue.delete(state.catalog, catalogItemId);
    },
    newCatalogItemToEditor(state) {
      state.editor = sureClone(DEFAULT_EDITOR);
    },
    newCollectionToCatalogItemEditor(state) {
      Vue.set(
        _.get(state, "editor"),
        "collection",
        sureClone(DEFAULT_COLLECTION_EDITOR),
      );
    },
    newCategoryToCatalogItemEditor(state) {
      Vue.set(
        _.get(state, "editor"),
        "category",
        sureClone(DEFAULT_CATEGORY_EDITOR),
      );
    },
    newChecklistRowToCatalogItemEditor(state) {
      Vue.set(
        _.get(state, "editor"),
        "checklistRow",
        sureClone(DEFAULT_CHECKLISTROW_EDITOR),
      );
    },
    newChecklistToCatalogItemEditor(state) {
      Vue.set(
        _.get(state, "editor"),
        "checklist",
        sureClone(DEFAULT_CHECKLIST_EDITOR),
      );
    },
  },
  actions: {
    async load({ commit }) {
      debug("load");
      let catalog = await ApiClient.get(`/catalog/v2`);
      debug("load", catalog);
      commit("set", ["catalog", _.keyBy(catalog, "targetId")]);
    },
    async getPdf({ commit }, pdfParameters) {
      debug(
        "getPdf for collection: " +
          pdfParameters.collection +
          ", parameters: " +
          pdfParameters.parameters,
      );
      let pdfUrl = await ApiClient.getFileByPostMethod(
        `/pdf?collectionId=${pdfParameters.collection}`,
        pdfParameters.parameters,
      );
      debug("getPdf", pdfUrl);
      commit("set", ["pdfDownloadUrl", pdfUrl]);
    },
    async moveUpCatalogItem(_, catalogItemId) {
      debug("moveUpCatalogItem", catalogItemId);
      let result = await ApiClient.post(
        `/catalogItems/${catalogItemId}/MoveUp`,
        catalogItemId,
      );
      debug("moveUpCatalogItem, result", result);
    },
    async moveDownCatalogItem(_, catalogItemId) {
      debug("moveDownCatalogItem", catalogItemId);
      let result = await ApiClient.post(
        `/catalogItems/${catalogItemId}/MoveDown`,
        catalogItemId,
      );
      debug("moveDownCatalogItem, result", result);
    },
    async createCatalogItem({ state, getters, commit }) {
      let catalogItem = sureClone(state.editor);
      _.unset(catalogItem, "id");
      debug("createCatalogItem", catalogItem);
      let response = await ApiClient.post("/catalogItems", catalogItem);
      debug("createCatalogItem - response", response);
      const parentItem = getters.catalogItemById(response.parentId);
      if (response.category != null) {
        commit("categories/addCategory", response.category, { root: true });

        var newItemCategory = {
          id: response.id,
          information: response.category.information ?? null,
          isFirstInSequence: false,
          isLastInSequence: true,
          name: response.category.name,
          parentId: response.parentId,
          restrictionMode: "Uncontrolled",
          sequenceNumber: response.sequenceNumber,
          targetId: response.category.id,
          type: "CATEGORY",
          children: [],
        };

        debug("new checklist tree item to be added is ", newItemCategory);

        parentItem.children.push(newItemCategory);
      }
      if (response.checklist != null) {
        commit("checklists/addChecklist", response.checklist, { root: true });

        //TODO This could be returned from backend
        var newItemChecklist = {
          id: response.id,
          information: response.checklist.information ?? null,
          isFirstInSequence: false,
          isLastInSequence: true,
          name: response.checklist.name,
          parentId: response.parentId,
          restrictionMode: "Uncontrolled",
          sequenceNumber: response.sequenceNumber,
          targetId: response.checklist.id,
          type: "CHECKLIST",
          children: [],
        };

        debug("new checklist tree item to be added is ", newItemChecklist);

        parentItem.children.push(newItemChecklist);
      }

      if (response.checklistRow != null) {
        commit("checklistRows/addChecklistRow", response.checklistRow, {
          root: true,
        });

        //TODO This could be returned from backend
        var newItemChecklistRow = {
          id: response.id,
          information: response.checklistRow.information ?? null,
          isFirstInSequence: false,
          isLastInSequence: true,
          name: response.checklistRow.name,
          parentId: response.parentId,
          restrictionMode: "Uncontrolled",
          sequenceNumber: response.sequenceNumber,
          targetId: response.checklistRow.id,
          type: "CHECKLISTROW",
          children: [],
        };

        debug("new checklist tree item to be added is ", newItemChecklistRow);

        parentItem.children.push(newItemChecklistRow);
      }

      var previousItem = parentItem.children?.find(
        (x) => x.sequenceNumber == response.sequenceNumber - 1,
      );
      if (previousItem != null) {
        previousItem.isLastInSequence = false;
      }

      return response;
    },

    async loadCatalogItemToEditor(
      { getters, commit, rootGetters },
      catalogItemId,
    ) {
      debug("loadCatalogItemToEditor", catalogItemId);
      const catalogItem = getters.catalogItemById(catalogItemId);
      debug("catalogItem", catalogItem);
      if (!catalogItem) {
        throw new Error("Catalog item not found");
      }

      commit("set", ["editor", sureClone(catalogItem)]);

      if (catalogItem.type === "CATEGORY") {
        var category = rootGetters["categories/categoryById"](
          catalogItem.targetId,
        );
        commit("set", ["editor.category", sureClone(category)]);
      }

      if (catalogItem.type === "CHECKLISTROW") {
        var checklistRow = rootGetters["checklistRows/checklistRowById"](
          catalogItem.targetId,
        );
        commit("set", ["editor.checklistRow", sureClone(checklistRow)]);
      }
    },
    async copyCatalogItem({ commit }, { catalogItemId, targetCatalogItemId }) {
      debug("copyCatalogItem", catalogItemId);
      let response = await ApiClient.post(
        `/catalogItems/${catalogItemId}/clone?TargetId=${targetCatalogItemId}`,
      );
      debug("copyCatalogItem - response", response);
      commit("addCatalogItem", response);
    },
    async syncChangesInTarget({ getters }, data) {
      const catalogItem = getters.catalogItemByTargetId(data.id);
      if (catalogItem) {
        catalogItem.name = data.name;
      }
    },
    async removeTemplateLinkingFromCatalogItem({ getters }, catalogItemId) {
      debug("removeTemplateLinkingFromCatalogItem", catalogItemId);
      let result = await ApiClient.post(
        `/catalogItems/${catalogItemId}/remove-template-linking`,
      );
      debug("removeTemplateLinkingFromCatalogItem, result", result);
      if (result) {
        result.forEach((element) => {
          const catalogItem = getters.catalogItemById(element.id);
          if (catalogItem) {
            catalogItem.isLinkedToTemplate = false;
          }
        });
      }
    },
    async deleteCatalogItem({ commit }, catalogItemId) {
      debug("deleteCatalogItem", catalogItemId);
      let result = await ApiClient.delete(`/catalogItems/${catalogItemId}`);
      debug("deleteCatalogItem - result", result);
      commit("deleteCatalogItem", catalogItemId);
      if (result?.categoryId != null) {
        commit("categories/deleteCategory", result.categoryId, { root: true });
      }
      if (result?.checklistId != null) {
        commit("checklists/deleteChecklist", result.checklistId, {
          root: true,
        });
      }
      if (result?.checklistRowId != null) {
        commit("checklistRows/deleteChecklistRow", result.checklistRowId, {
          root: true,
        });
      }
    },
  },
});
