import _ from "lodash";
import * as _debug from "debug";
import ApiClient from "@/lib/ApiClient";

import ModuleBase from "../../common/ModuleBase";
import { objectDiff, sureClone } from "@/lib/DataUtils";
import Vue from "vue";

const debug = _debug(`${process.env.VUE_APP_PREFIX}:TemplateCollectionsStore`);

const DEFAULT_EDITOR = {
  id: null,
  name: null,
  description: null,
  organisationId: null,
};

export default ModuleBase({
  state: {
    templateCollections: {},
    editor: sureClone(DEFAULT_EDITOR),
  },

  getters: {
    templateCollections({ templateCollections }) {
      let values = _.values(templateCollections);
      return values;
    },
    templateCollectionById:
      ({ templateCollections }) =>
      (id) => {
        var x = _.values(templateCollections).find((c) => c.id === id);
        return x;
      },
    editorChanges(state) {
      const id = _.get(state, "editor.id");
      if (!id) {
        return null;
      }
      const original = _.get(state.templateCollections, id);
      if (!original) {
        return null;
      }
      return objectDiff(original, state.editor);
    },
    editorHasChanges(state, getters) {
      let diff = getters.editorChanges;
      return diff && !_.isEmpty(diff);
    },
  },

  mutations: {
    newTemplateCollectionToEditor(state) {
      state.editor = sureClone(DEFAULT_EDITOR);
    },
    addTemplateCollection(state, templateCollection) {
      Vue.set(
        state.templateCollections,
        templateCollection.id,
        templateCollection,
      );
    },
    deleteVessel(state, templateCollectionId) {
      Vue.delete(state.templateCollections, templateCollectionId);
    },
  },
  actions: {
    async load({ commit }) {
      debug("load");
      let templateCollections = await ApiClient.get(`/templateCollections`);
      debug("load", templateCollections);
      commit("set", [
        "templateCollections",
        _.keyBy(templateCollections, "id"),
      ]);
    },
    async loadTemplateCollectionToEditor(
      { state, commit },
      templateCollectionId,
    ) {
      debug("loadTemplateCollectionToEditor", templateCollectionId);
      const templateCollection = _.get(
        state.templateCollections,
        templateCollectionId,
      );
      debug("loadTemplateCollectionToEditor", templateCollectionId);
      if (!templateCollection) {
        throw new Error("TemplateCollection not found");
      }
      commit("set", ["editor", sureClone(templateCollection)]);
    },
    async saveTemplateCollectionInEditor({ state, getters, commit, dispatch }) {
      let templateCollectionId = state.editor.id;
      if (!getters.editorHasChanges) {
        debug("saveTemplateCollectionInEditor", "Nothing to save");
        return;
      }
      let modifiedTemplateCollection = sureClone(state.editor);
      debug(
        "saveTemplateCollectionInEditor",
        templateCollectionId,
        modifiedTemplateCollection,
      );
      let result = await ApiClient.put(
        `/templateCollections/${templateCollectionId}`,
        modifiedTemplateCollection,
      );

      debug("saveTemplateCollectionInEditor, result", result);

      if (result) {
        commit("addTemplateCollection", result);
        dispatch("templates/syncChangesInTarget", result, { root: true });
      }
    },
  },
});
