export const fullscreenMixin = {
  data() {
    return {
      isFullscreen: false,
    };
  },
  methods: {
    toggleFullscreen() {
      if (!document.fullscreenElement) {
        this.enterFullscreen();
      } else {
        this.exitFullscreen();
      }
    },
    enterFullscreen() {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        // Safari
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        // Firefox
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.msRequestFullscreen) {
        // IE/Edge
        document.documentElement.msRequestFullscreen();
      }
      this.isFullscreen = true;
    },
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        // Safari
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // Firefox
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        // IE/Edge
        document.msExitFullscreen();
      }
      this.isFullscreen = false;
    },
    updateFullscreenState() {
      const old = this.isFullscreen;
      this.isFullscreen = !!document.fullscreenElement;
      // const isBrowserFullscreen =
      //   window.innerWidth === screen.width &&
      //   window.innerHeight === screen.height;
      // this.isFullscreen = !!document.fullscreenElement || isBrowserFullscreen;
      if (old !== this.isFullscreen) {
        this.$emit("fullscreen-change", this.isFullscreen);
      }
    },
  },
  mounted() {
    // window.addEventListener("resize", this.updateFullscreenState);
    document.addEventListener("fullscreenchange", this.updateFullscreenState);
    document.addEventListener(
      "webkitfullscreenchange",
      this.updateFullscreenState,
    ); // Safari
    document.addEventListener(
      "mozfullscreenchange",
      this.updateFullscreenState,
    ); // Firefox
    document.addEventListener("MSFullscreenChange", this.updateFullscreenState); // IE/Edge
  },
  beforeDestroy() {
    // window.removeEventListener("resize", this.updateFullscreenState);
    document.removeEventListener(
      "fullscreenchange",
      this.updateFullscreenState,
    );
    document.removeEventListener(
      "webkitfullscreenchange",
      this.updateFullscreenState,
    );
    document.removeEventListener(
      "mozfullscreenchange",
      this.updateFullscreenState,
    );
    document.removeEventListener(
      "MSFullscreenChange",
      this.updateFullscreenState,
    );
  },
};
