<template>
  <v-container fill-height class="d-flex justify-center flex-column">
    <v-btn
      v-if="!$auth.isAuthenticated && !$auth.loading"
      color="primary"
      large
      @click="login"
    >
      Kirjaudu palveluun
    </v-btn>
  </v-container>
</template>
<style lang="sass" scoped></style>
<script>
export default {
  name: "HomeView",
  components: {},
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
  },
};
</script>
