<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-if="$auth.isAuthenticated && isDataReady"
      v-model="drawer"
      app
      clipped
      color="#005697"
      dark
    >
      <v-list dense background="green">
        <v-list-item
          v-for="item in items"
          :key="item.text"
          link
          :to="item.target"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> {{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>
      <v-spacer></v-spacer>
      <v-list dense>
        <v-list-item link @click="logout">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-title>Kirjaudu ulos</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      v-if="$auth.isAuthenticated && isDataReady"
      app
      clipped-left
      color="primary"
      dense
    >
      <v-app-bar-nav-icon
        class="white--text"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title class="mr-12 align-center white--text">
        SMPS Tarkistuslistat
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="text-no-wrap white--text text--xs" style="font-size: small">
        <v-row>
          <v-col style="align-self: center">
            <v-icon large color="white">mdi-account</v-icon>
          </v-col>
          <v-col style="align-self: center">
            <v-row>
              {{ userName }}
            </v-row>
            <v-row> {{ userType }} / {{ userOrganisation }} </v-row>
          </v-col>
        </v-row>
      </div>
    </v-app-bar>

    <v-main style="background: #ecf4f7">
      <router-view></router-view>
    </v-main>
    <AppToast></AppToast>
    <ConfirmDialog></ConfirmDialog>
    <InfoDialog></InfoDialog>
    <LoadingDialog :show="isLoading"></LoadingDialog>
  </v-app>
</template>
<script>
import AppToast from "./components/common/Toast";
import ConfirmDialog from "./components/common/Confirm";
import LoadingDialog from "./components/common/Loading";
import InfoDialog from "./components/common/Info";
import { fullscreenMixin } from "./mixins/FullscreenMixin";

export default {
  name: "App",
  components: {
    AppToast,
    ConfirmDialog,
    LoadingDialog,
    InfoDialog,
  },
  mixins: [fullscreenMixin],
  data: () => ({
    drawer: null,
    wasDrawerShown: false,
    isLoading: false,
    isDataReady: false,
  }),
  computed: {
    items: {
      get() {
        let me = this;
        var basicMenuItems = [];
        console.log("...", me.account.enabledFeatures);
        if (me.checklistsFeatureEnabled) {
          basicMenuItems.push({
            icon: "mdi-format-list-checks",
            text: "Tarkistuslistat",
            target: "/checklists",
          });
        }

        if (me.announcementsFeatureEnabled) {
          basicMenuItems.push({
            icon: "mdi-bullhorn",
            text: "Tiedotuslista",
            target: "/announcement-list",
          });
        }

        var powerUserMenuItems = [];
        if (me.checklistsFeatureEnabled) {
          powerUserMenuItems.push({
            icon: "mdi-file-pdf-box",
            text: "Paperilistojen luonti",
            target: "/pdf-generation",
          });
        }

        var globalOrganisationPowerUserMenuItems = [
          {
            icon: "mdi-view-list-outline",
            text: "Tarkistuslistapohjat",
            target: "/templates",
          },
          {
            icon: "mdi-ferry",
            text: "Alukset",
            target: "/vessels",
          },
          {
            icon: "mdi-account-group-outline",
            text: "Yhdistykset ja käyttäjät",
            target: "/organisations",
          },
        ];

        var systemAdminMenuItems = [];

        var menuItems = [];

        if (this.$auth.isBasicUser) {
          menuItems = basicMenuItems;
        }

        if (this.$auth.isLocalStationPowerUser) {
          menuItems = basicMenuItems.concat(powerUserMenuItems);
        }

        if (this.$auth.isGlobalOrganisationPowerUser) {
          menuItems = globalOrganisationPowerUserMenuItems;
        }

        if (this.$auth.isSystemAdmin) {
          menuItems = basicMenuItems
            .concat(powerUserMenuItems)
            .concat(globalOrganisationPowerUserMenuItems)
            .concat(systemAdminMenuItems);
        }
        return menuItems;
      },
    },
    userName: {
      get() {
        return this.$auth.user.name;
      },
    },
    userType: {
      get() {
        if (this.$auth.isBasicUser) {
          return "Aseman peruskäyttäjä";
        }

        if (this.$auth.isLocalStationPowerUser) {
          return "Aseman pääkäyttäjä";
        }

        if (this.$auth.isGlobalOrganisationPowerUser) {
          return "SMPS Pääkäyttäjä";
        }

        if (this.$auth.isSystemAdmin) {
          return "Järjestelmän pääkäyttäjä";
        }
        return "";
      },
    },
    account: {
      get() {
        return this.$store.getters["account/info"];
      },
    },
    checklistsFeatureEnabled: {
      get() {
        return this.$store.getters["account/checklistsFeatureEnabled"];
      },
    },
    announcementsFeatureEnabled: {
      get() {
        return this.$store.getters["account/announcementsFeatureEnabled"];
      },
    },
    userOrganisation: {
      get() {
        return this.$auth.isSystemAdmin
          ? "Järjestelmän ylläpitäjä"
          : this.account.organisationName;
      },
    },
  },
  methods: {
    // Log the user out
    async logout() {
      let me = this;
      await me.$store.commit("appLocal/clearLocalData");
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    onFullscreenChange() {
      if (this.isFullscreen) {
        this.wasDrawerShown = this.drawer;
        this.drawer = false;
      } else {
        this.drawer = this.wasDrawerShown || this.drawer;
      }
    },
    resolveRedirectTarget() {
      let me = this;
      if (me.$auth.isSystemAdmin || me.$auth.isGlobalOrganisationPowerUser) {
        return "/templates";
      }
      if (me.checklistsFeatureEnabled) {
        return "/checklists";
      }
      if (me.announcementsFeatureEnabled) {
        return "/announcement-list";
      }
      return "/";
    },
  },
  async mounted() {
    let me = this;

    this.$on("fullscreen-change", this.onFullscreenChange);

    this.$auth.$watch("loading", async (loading) => {
      if (loading === false && this.$auth.isAuthenticated === true) {
        try {
          me.isLoading = true;
          await me.$store.dispatch("account/load");

          if (
            me.$auth.isSystemAdmin ||
            me.$auth.isGlobalOrganisationPowerUser
          ) {
            await me.$store.dispatch("templates/loadRoot");
            await me.$store.dispatch("templateCollections/load");
            await me.$store.dispatch("organisations/load");
            await me.$store.dispatch("vessels/load");
            await me.$store.dispatch("users/load");
            await me.$store.dispatch("features/load");
            await me.$store.dispatch("roles/load");
          } else {
            await me.$store.dispatch("collections/load");

            if (me.checklistsFeatureEnabled) {
              await me.$store.dispatch("categories/load");
              await me.$store.dispatch("checklists/load");
              await me.$store.dispatch("checklistRows/load");
              await me.$store.dispatch("catalog/load");
            }
          }

          me.isDataReady = true;
          me.isLoading = false;

          console.log("current route", this.$route.name);
          if (this.$route.name == "Home") {
            var redirectTarget = me.resolveRedirectTarget();
            me.$redirect(redirectTarget);
          }
        } catch (err) {
          if (err.message !== "cancel") {
            me.isLoading = false;
            await me.$info(err.message, "Yhdistäminen palveluun epäonnistui!");
            me.logout();
          }
        }
      }
    });
  },
};
</script>
